import { PageHeading } from "components/pageHeading/PageHeading";
import { SectionWrapper } from "components/section/Section";
import styles from "pages/Torq/TorqPage.module.scss";
import torqDemoVideo from "videos/torq-demo.mp4";
import torqDemoImage from "images/torq-demo.png";

export default function TorqPage() {
  return (
    <div>
      <PageHeading header="Torq" />
      <SectionWrapper
        boxHeaderBannerImage={torqDemoImage}
        boxHeaderBannerVideo={torqDemoVideo}
        largeBoxHeaderBanner
      >
        <div>
          <p>
            Why settle for less? With Torq, our world-class node management
            tool, we provide the most reliable, scalable, and secure Bitcoin
            Lightning payments on the market. We've got the tech to do it better
            than anyone else.
          </p>
          <p>
            Want to run your own Lightning node? Torq makes it incredibly
            simple. Our powerful tool lets you manage your nodes with ease—no
            complexity, just seamless control.
          </p>
          <div className={styles.buttonWrapper}>
            <a
              className={styles.linkButton}
              href={"https://torq.co"}
              rel={"noreferrer"}
              target={"_blank"}
            >
              To Torq Site
            </a>
          </div>
        </div>
        <div></div>
      </SectionWrapper>
    </div>
  );
}
