import { PageHeading } from "components/pageHeading/PageHeading";
import { SectionWrapper } from "components/section/Section";
import styles from "pages/Documentation/DocumentationPage.module.scss";
import docsScreenshot from "images/api-doc-screenshot.png";

export default function DocumentationPage() {
  return (
    <div>
      <PageHeading header="Documentation" />
      <SectionWrapper boxHeaderBannerImage={docsScreenshot}>
        <div>
          <p>
            Ready to build? Our API docs are your new best friend. Clear, fast,
            and frustration-free—let's get coding!
          </p>
          <div className={styles.buttonWrapper}>
            <a
              className={styles.linkButton}
              href={"https://docs.mion.group"}
              rel={"noreferrer"}
              target={"_blank"}
            >
              Get Started
            </a>
          </div>
        </div>
      </SectionWrapper>
    </div>
  );
}
