import styles from "components/pillLabel/PillLabel.module.scss";
import classNames from "classnames";

export default function PillLabel(props: {
  label: string;
  color?: "white" | "purple" | "black";
}) {
  return (
    <div
      className={classNames(styles.Label, {
        [styles.white]: props.color === "white",
        [styles.black]: props.color === "black",
      })}
    >
      {props.label}
    </div>
  );
}
