import classNames from "classnames";
import styles from "components/keyFigures/KeyFigures.module.scss";
import { ReactNode } from "react";
import PillLabel from "../pillLabel/PillLabel";

export function KeyFiguresSection(props: { children: ReactNode }) {
  return (
    <div className={styles.keyFiguresWrapper}>
      <div className={styles.keyFiguresContainer}>{props.children}</div>
    </div>
  );
}

export function KeyFiguresHeader(props: {
  title: string | ReactNode;
  label?: string;
  titleAlignLeft?: boolean;
}) {
  return (
    <div className={classNames(styles.keyFiguresHeader)}>
      <PillLabel label={props.label || ""} color={"white"} />
      {props.title}
    </div>
  );
}

export function KeyFiguresGrid(props: { children: ReactNode }) {
  return <div className={styles.keyFiguresGrid}>{props.children}</div>;
}

export function KeyFigure(props: {
  value: string;
  label: string;
  responsiveValueSize?: boolean;
  gridSpan2?: boolean;
}) {
  return (
    <div
      className={classNames(styles.keyFigureWrapper, {
        [styles.gridSpan2]: props.gridSpan2,
      })}
    >
      <div
        className={classNames(styles.keyFigureValue, {
          [styles.responsiveValueSize]: props.responsiveValueSize,
        })}
      >
        {props.value}
      </div>
      <div className={styles.keyFigureLabel}>{props.label}</div>
    </div>
  );
}
