import {
  FeatureElement,
  FeaturesContent,
} from "components/featuresSection/FeaturesSection";
import styles from "pages/Home/HomePage.module.scss";
import commonStyles from "styles/common.module.scss";
import {
  KeyFigure,
  KeyFiguresGrid,
  KeyFiguresHeader,
  KeyFiguresSection,
} from "components/keyFigures/KeyFigures";
import { HomeHeading } from "components/homeHeading/HomeHeading";
import { SectionWrapper } from "components/section/Section";
import { useEffect, useState } from "react";
import { CheckoutDemoVideoSection } from "components/checkoutDemoVideoSection/CheckoutDemoVideoSection";
import { ContactUsForm } from "components/contactUsForm/ContactUsForm";
// import familiarIntegrationIllustration from "animations/home/familiar_integration.json";
// import regulationSolvedIllustration from "animations/home/regulation_solved_transparent.json";
// import noVolatilityIllustration from "animations/home/no_volatility.json";
// import accountingAsUsualIllustration from "animations/home/accounting_as_usual.json";

const getMarketSizePeople = () => {
  const now = new Date();
  const currentYear = now.getFullYear();

  if (currentYear === 2025) {
    return "60m";
  }

  if (currentYear === 2026) {
    return "67m";
  }

  return "76m";
};

export default function HomePage() {
  const [marketCap, setMarketCap] = useState<number>(1500000000000);
  const [expandedFeatureElement, setExpandedFeatureElement] = useState<
    number | null
  >(null);

  const marketCapBillions = (marketCap / 1e9).toFixed(0);

  useEffect(() => {
    const fetchMarketCap = async () => {
      try {
        const response = await fetch(
          "https://api.coingecko.com/api/v3/coins/markets?vs_currency=eur&ids=bitcoin"
        );
        const data = await response.json();
        setMarketCap(data[0].market_cap);
      } catch (err) {
        console.error("getting bitcoin market cap", err);
      }
    };

    fetchMarketCap();
  }, []);

  return (
    <div
      className={styles.homePageWrapper}
      // close feature element when clicking outside
      onClick={() => setExpandedFeatureElement(null)}
    >
      <HomeHeading />
      <SectionWrapper transparentSection>
        <FeaturesContent>
          <FeatureElement
            title={
              <span>
                <span className={commonStyles.grayText}>Integrates like</span>{" "}
                <span>standard payment solutions.</span>
              </span>
            }
            label={"Familiar integration"}
            index={1}
            expanded={expandedFeatureElement === 1}
            setExpanded={(expanded) =>
              setExpandedFeatureElement(expanded ? 1 : null)
            }
            faded={
              expandedFeatureElement !== null && expandedFeatureElement !== 1
            }
            expandContent={
              <div>
                <div>
                  Our REST API makes adding Bitcoin Lightning payments a breeze.
                  It integrates seamlessly into your existing checkout
                  flow—whether it's e-commerce or POS—just like any traditional
                  payment solution.
                </div>
                <br />
                <div>
                  No extra hardware, no complex processes. It’s the same simple
                  setup, but with cutting-edge technology working behind the
                  scenes.
                </div>
              </div>
            }
          />
          <FeatureElement
            title={
              <span>
                <span>Instant Bitcoin to Euro conversion, </span>{" "}
                <span className={commonStyles.grayText}>everytime.</span>
              </span>
            }
            label={"No volatility"}
            index={2}
            expanded={expandedFeatureElement === 2}
            setExpanded={(expanded) =>
              setExpandedFeatureElement(expanded ? 2 : null)
            }
            faded={
              expandedFeatureElement !== null && expandedFeatureElement !== 2
            }
            expandContent={
              <div>
                Say goodbye to volatility concerns. We instantly convert every
                Bitcoin transaction into Euros, so you never have to worry about
                fluctuating prices. Your payouts are always stable, predictable,
                and in the currency you're used to, keeping things simple and
                stress-free.
              </div>
            }
          />
          <FeatureElement
            title={
              <span>
                <span>Payouts in Euros,</span>{" "}
                <span className={commonStyles.grayText}>within 48 hours.</span>
              </span>
            }
            label={"Accounting as usual"}
            index={3}
            expanded={expandedFeatureElement === 3}
            setExpanded={(expanded) =>
              setExpandedFeatureElement(expanded ? 3 : null)
            }
            faded={
              expandedFeatureElement !== null && expandedFeatureElement !== 3
            }
            expandContent={
              <div>
                Keep your accounting smooth and simple. With our solution,
                Bitcoin payments are converted to Euros and paid out within 48
                hours. No need to adjust your existing processes—payouts happen
                just like with any other payment method. It's business as usual,
                only faster and more efficient.
              </div>
            }
          />
          <FeatureElement
            title={
              <span>
                <span>Fully compliant</span>{" "}
                <span className={commonStyles.grayText}>and authorized.</span>{" "}
                {/*<span className={commonStyles.grayText}>throughout Europe.</span>*/}
              </span>
            }
            label={"Regulation solved"}
            index={4}
            expanded={expandedFeatureElement === 4}
            setExpanded={(expanded) =>
              setExpandedFeatureElement(expanded ? 4 : null)
            }
            faded={
              expandedFeatureElement !== null && expandedFeatureElement !== 4
            }
            expandContent={
              <div>
                <div>
                  We handle all the heavy lifting when it comes to regulation,
                  so you don’t have to. From AML requirements and blockchain
                  analysis to PEP & sanctions checks, we’ve got it covered. When
                  necessary, we also take care of KYC procedures, ensuring every
                  transaction is safe and compliant.
                </div>
                <br />
                <div>
                  As a fully regulated provider in Europe, we meet the highest
                  standards, giving you peace of mind while offering
                  cutting-edge Bitcoin payments. Stay compliant, stay
                  secure—without the headache.
                </div>
              </div>
            }
          />
        </FeaturesContent>
      </SectionWrapper>
      <SectionWrapper transparentSection>
        <CheckoutDemoVideoSection />
      </SectionWrapper>
      <KeyFiguresSection>
        <KeyFiguresHeader
          label={"Key figures"}
          title={
            <span>
              <span>Allow your customers to pay in Bitcoin</span>{" "}
              <span className={commonStyles.grayTextOnNonWhite}>
                with lightning fast payments, secure, efficient and risk free
                while always receiving Euros.
              </span>
            </span>
          }
          titleAlignLeft
        />
        <KeyFiguresGrid>
          <KeyFigure value={"80%"} label={"save 80% in transaction fees"} />
          <KeyFigure
            value={getMarketSizePeople()}
            label={"new customers in Europe"}
          />
          <KeyFigure
            value={"€" + marketCap.toLocaleString()}
            label={
              "tap into " + marketCapBillions + " billion € worth of bitcoin"
            }
            responsiveValueSize
            gridSpan2
          />
        </KeyFiguresGrid>
      </KeyFiguresSection>
      <SectionWrapper tightVerticalPadding>
        <ContactUsForm />
      </SectionWrapper>
    </div>
  );
}
