import styles from "./Background.module.scss";
import heroVideo1 from "./videos/hero-video-1.mp4";
import heroVideo2 from "./videos/hero-video-2.mp4";
import heroVideo3 from "./videos/hero-video-3.mp4";
import heroVideo4 from "./videos/hero-video-4.mp4";
import heroVideo5 from "./videos/hero-video-5.mp4";
import heroVideo6 from "./videos/hero-video-6.mp4";
import { useEffect, useState, useContext } from "react";
import { LayoutContext } from "layout/Default/LayoutContext";

// dictionary of video files, using numbers as keys
const videoFiles = {
  1: heroVideo1,
  2: heroVideo2,
  3: heroVideo3,
  4: heroVideo4,
  5: heroVideo5,
  6: heroVideo6,
};

// Images should be optimized in size. Right now they are pretty high resolution.

export default function Background() {
  // const location = useLocation();

  const layoutContext = useContext(LayoutContext);

  const [videoIndex, setVideoIndex] = useState<
    keyof typeof videoFiles | undefined
  >();

  useEffect(() => {
    // get the last number selected from local storage, if there is no then use 0
    let lastVideo = localStorage.getItem("lastVideo") || "0";

    // increment the number by 1, if it is 6 then reset to 1
    if (parseInt(lastVideo) >= 6) {
      localStorage.setItem("lastVideo", "0");
      lastVideo = "0";
    }

    const vIdx = (parseInt(lastVideo) + 1) as keyof typeof videoFiles;

    setVideoIndex(vIdx);

    // store the last number selected in local storage
    localStorage.setItem("lastVideo", vIdx.toString());
  }, []);

  useEffect(() => {
    if (videoIndex) {
      layoutContext.setBgVideoFile(videoFiles[videoIndex]);
    }
  }, [videoIndex, layoutContext]);

  const cssBackgroundVideo = {
    backgroundImage: videoIndex
      ? "url(" + videoFiles[videoIndex] + ")"
      : undefined,
  };

  // We render a background image first which should be a static image of the first frame of the video.
  // For now use an image from the image folder.
  return (
    <div className={styles.backgroundWrapper}>
      <div className={styles.backgroundImage} />
      <div className={styles.backgroundVideoCss} style={cssBackgroundVideo} />
      <div className={styles.backgroundVideo}>
        <video autoPlay loop muted playsInline>
          {videoIndex && (
            <source src={videoFiles[videoIndex]} type="video/mp4" />
          )}
        </video>
      </div>
    </div>
  );
}
