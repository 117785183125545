import styles from "components/checkoutDemoVideoSection/CheckoutDemoVideoSection.module.scss";
import checkoutDemoVideo from "videos/checkout.mp4";

export function CheckoutDemoVideoSection() {
  return (
    <div className={styles.checkoutDemoVideoSectionContainer}>
      <div className={styles.checkoutDemoVideoWrapper}>
        <div className={styles.checkoutDemoVideoContainer}>
          <video
            autoPlay
            loop
            muted
            playsInline
            className={styles.checkoutDemoVideo}
          >
            <source src={checkoutDemoVideo} type="video/mp4" />
          </video>
        </div>
      </div>
      <div className={styles.textSideWrapper}>
        <div className={styles.textContainer}>
          <div className={styles.opaque}>Secure.</div>
          <div className={styles.opaque}>Fast.</div>
          <div className={styles.opaque}>Frustration-Free.</div>
          <div>Payments done right.</div>
        </div>
      </div>
    </div>
  );
}
