import classNames from "classnames";
import styles from "components/aboutSection/AboutSection.module.scss";
import PillLabel from "components/pillLabel/PillLabel";
import { ReactNode } from "react";
import commonStyles from "styles/common.module.scss";
import bitcoinerJobsLogo from "images/bitcoiner-jobs-logo.png";

type AboutElementSideBySideProps = {
  title: string | ReactNode;
  children: ReactNode;
};

export function AboutElementSideBySide(props: AboutElementSideBySideProps) {
  return (
    <div className={styles.aboutElementWrapper}>
      <div className={styles.aboutElementSideBySideContainer}>
        <div className={styles.TitleWrapper}>
          <div className={styles.Title}>{props.title}</div>
        </div>
        <div className={styles.aboutElementContentWrapper}>
          <div className={styles.aboutElementContent}>{props.children}</div>
        </div>
      </div>
    </div>
  );
}

export function AboutFlowChartIllustration(props: { animationPath: string }) {
  const backgroundStyle = {
    backgroundImage: `url(${props.animationPath})`,
  };

  return (
    <div className={styles.illustrationWrapper}>
      <div className={styles.illustrationVideoCSS} style={backgroundStyle} />
      <div className={styles.flowChartIllustrationVideoContainer}>
        <video
          autoPlay
          loop
          muted
          playsInline
          className={styles.flowChartIllustrationVideo}
        >
          <source src={props.animationPath} type="video/mp4" />
        </video>
      </div>
    </div>
  );
}

export function AboutElementTeam() {
  return (
    <div className={styles.aboutElementWrapper}>
      <div className={styles.aboutElementTeamContainer}>
        <div className={styles.TitleWrapper}>
          <PillLabel label={"Our people"} />
          <div className={styles.Title}>
            <span>An expert team</span>{" "}
            <span className={commonStyles.grayText}>
              with a <br className={commonStyles.hideOnMobile} />
              passion for payments.
            </span>
          </div>
        </div>
        <AboutElementJoinUs />
        <div className={styles.aboutElementContentWrapper}>
          <div className={styles.teamGrid}>
            <TeamElement
              name={"Daniel Tesch Martins"}
              role={"COO and Co-founder"}
              description={
                "Implemented the largest Mobile Payment soulution in Austria."
              }
            />
            <TeamElement
              name={"Henrik Skogstrøm"}
              role={"CEO and Co-founder"}
              description={
                "Founding member of Arcario/K33 and lead them to a $800M IPO as CTO."
              }
            />
            <TeamElement
              name={"Kobe Nys"}
              role={"CTO"}
              description={
                "16 years in the international remittance industry building scalable robust systems."
              }
            />
            <TeamElement
              name={"Juho Yrjölä"}
              role={"Developer"}
              description={
                "Talented Lightning developer with 5 years of experience. Master in Tech. Automation, thesis on Bitcoin & Lightning."
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export function TeamElement(props: {
  name: string;
  role: string;
  description: string;
}) {
  return (
    <div className={styles.teamItem}>
      <div className={styles.teamItemName}>{props.name}</div>
      <div className={styles.teamItemRole}>{props.role}</div>
      <div className={styles.teamItemDescription}>{props.description}</div>
    </div>
  );
}

export function AboutSectionHeader(props: { titleAlignLeft?: boolean }) {
  return (
    <div
      className={classNames(styles.aboutSectionHeader, {
        [styles.titleLeft]: props.titleAlignLeft,
      })}
    >
      <h2 style={{ maxWidth: "960px" }}>
        <span className={commonStyles.grayText}>
          —Our mission is to make Bitcoin transactions
        </span>{" "}
        accessible for merchants and consumers.
      </h2>
      <div className={styles.aboutSectionHeaderSubText}>
        <div>Henrik Skogstrøm</div>
        <div className={styles.aboutSectionHeaderSubTextTitle}>
          CEO and Co-founder
        </div>
      </div>
    </div>
  );
}

export function AboutElementJoinUs() {
  return (
    <div className={styles.joinUsContainer}>
      <div className={styles.joinUsText}>Join us!</div>
      <div>
        Check out our vacant positions at Bitcoiner Jobs. Or get in touch at{" "}
        <a href={"mailto:hello@mion.group"}>hello@mion.group</a>.
      </div>
      <a
        className={styles.bitcoinerJobsButton}
        href={"https://bitcoinerjobs.com/company/mion"}
        target={"_blank"}
        rel={"noreferrer"}
      >
        <img src={bitcoinerJobsLogo} alt={"Bitcoiner Jobs logo"} />
        <div>Bitcoiner Jobs</div>
      </a>
    </div>
  );
}
