import styles from "components/section/Section.module.scss";
import classNames from "classnames";
import { ReactNode, useEffect, useRef, useState } from "react";

type WrapperProps = {
  children: ReactNode;
  transparentSection?: boolean;
  homeHeadingSection?: boolean;
  tightVerticalPadding?: boolean;
  boxHeaderBannerImage?: string;
  boxHeaderBannerVideo?: string;
  largeBoxHeaderBanner?: boolean;
};

export function SectionWrapper(props: WrapperProps) {
  const [videoIsLoaded, setVideoIsLoaded] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (!props.boxHeaderBannerVideo) {
      return;
    }

    const handleLoadedData = () => {
      setVideoIsLoaded(true);
    };

    const videoElement = videoRef.current;

    if (videoElement) {
      videoElement.addEventListener("loadeddata", handleLoadedData);
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener("loadeddata", handleLoadedData);
      }
    };
  }, [props.boxHeaderBannerVideo]);

  return (
    <div className={styles.sectionWrapper}>
      <div
        className={classNames(styles.sectionContainer, {
          [styles.transparentSectionContainer]: props.transparentSection,
          [styles.boxSectionContainer]: !props.transparentSection,
          [styles.tightVerticalPadding]: props.tightVerticalPadding,
        })}
      >
        {(!!props.boxHeaderBannerImage || !!props.boxHeaderBannerVideo) && (
          <div
            className={classNames(styles.headerBanner, {
              [styles.verticalAlignBackground]: !!props.boxHeaderBannerVideo,
              [styles.largeHeaderBanner]: props.largeBoxHeaderBanner,
            })}
            style={{
              backgroundImage: `url(${props.boxHeaderBannerImage})`,
            }}
          >
            {!!props.boxHeaderBannerVideo && (
              <video
                ref={videoRef}
                autoPlay
                loop
                muted
                playsInline
                className={videoIsLoaded ? styles.visible : undefined}
              >
                <source src={props.boxHeaderBannerVideo} type="video/mp4" />
              </video>
            )}
            <div className={styles.bannerFadeOverlay} />
          </div>
        )}
        <div className={styles.sectionContent}>{props.children}</div>
      </div>
    </div>
  );
}
