import styles from "components/homeHeading/HomeHeading.module.scss";

export function HomeHeading() {
  return (
    <div className={styles.homeHeadingWrapper}>
      <div className={styles.homeHeadingContainer}>
        <h1 className={styles.header}>
          <span>
            Accept bitcoin,{" "}
            <span className={styles.headerGray}>without bitcoin</span>
          </span>
        </h1>
      </div>
    </div>
  );
}
