import classNames from "classnames";
import styles from "components/featuresSection/FeaturesSection.module.scss";
import PillLabel from "components/pillLabel/PillLabel";
import { ReactNode } from "react";
import Lottie from "lottie-react";

type FeatureElementProps = {
  title: string | ReactNode;
  label: string;
  index: 1 | 2 | 3 | 4; // for the grid
  expanded: boolean;
  setExpanded: (expanded: boolean) => void;
  faded: boolean;
  expandContent?: ReactNode;
};

export function FeaturesContent(props: { children: ReactNode }) {
  return <div className={styles.featuresSectionContent}>{props.children}</div>;
}

export function FeatureElement(props: FeatureElementProps) {
  return (
    <div
      className={classNames(styles.featureElementContainer, {
        [styles["gridItem" + props.index]]: props.index,
      })}
      onClick={(e) => {
        // do not close on click if expanded
        if (props.expanded) {
          e.stopPropagation();
        }
      }}
    >
      <div
        className={classNames(styles.featureElementContent, {
          [styles.expanded]: props.expanded,
          [styles.faded]: props.faded,
        })}
      >
        <div className={styles.content}>
          <div className={styles.titleContent}>
            <PillLabel label={props.label} color="purple" />
            <div className={styles.Title}>{props.title}</div>
          </div>
          <div className={styles.expandContentWrapper}>
            <div className={styles.expandContent}>{props.expandContent}</div>
          </div>
        </div>
        <div className={styles.expandButtonWrapper}>
          {!!props.expandContent && (
            <div
              className={styles.expandButton}
              onClick={(e) => {
                e.stopPropagation();
                props.setExpanded(!props.expanded);
              }}
            >
              {props.expanded ? "Close" : "Learn more"}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export function FeatureLottie(props: { data: any }) {
  return (
    <div className={styles.illustrationWrapper}>
      <div className={styles.featureIllustrationVideoContainer}>
        {props.data && <Lottie animationData={props.data} />}
      </div>
    </div>
  );
}

export function FeaturesSectionHeader(props: {
  title: string | ReactNode;
  titleAlignLeft?: boolean;
}) {
  return (
    <h2
      className={classNames(styles.featuresSectionHeader, {
        [styles.titleLeft]: props.titleAlignLeft,
      })}
    >
      {props.title}
    </h2>
  );
}
