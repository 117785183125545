import React, { createContext, ReactNode, useState } from "react";

type LayoutContextType = {
  bgVideoFile: string;
  setBgVideoFile: (color: string) => void;
};

export const LayoutContext = createContext<LayoutContextType>({
  bgVideoFile: "",
  setBgVideoFile: () => {},
});

export function LayoutContextProvider(props: { children: ReactNode }) {
  const [bgVideoFile, setBgVideoFile] = useState("");

  return (
    <LayoutContext.Provider
      value={{ bgVideoFile: bgVideoFile, setBgVideoFile: setBgVideoFile }}
    >
      {props.children}
    </LayoutContext.Provider>
  );
}
