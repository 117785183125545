import { useState } from "react";
import styles from "components/contactUsForm/ContactUsForm.module.scss";

// In dev remember to change to dev server!
const serverUrl = "https://app.mion.group";
// const serverUrl = "http://localhost:8081";

const path = "/api/website/send-contact-form-email";

export function ContactUsForm() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (loading) {
      return;
    }

    if (!name || !email || !message) {
      console.error("Form fields are missing, not submitting the form");
      return;
    }

    setLoading(true);
    setSuccess(false);
    setError(false);

    const body = {
      contactName: name,
      contactEmail: email,
      message: message,
    };

    try {
      const response = await fetch(serverUrl + path, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      setSuccess(true);
      setError(false);
      console.log("Form submitted successfully");

      // Reset the form on success
      setName("");
      setEmail("");
      setMessage("");
    } catch (error) {
      setSuccess(false);
      setError(true);
      console.error("Error submitting the form:", error);
    }

    setLoading(false);
  };

  return (
    <div className={styles.contactUsContainer}>
      <div className={styles.contactUsFormContainer}>
        <form className={styles.contactUsForm} onSubmit={handleSubmit}>
          <h2>Contact Us</h2>
          <div className={styles.formGroup}>
            <label htmlFor="name">Name *</label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="email">Email *</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="message">Message *</label>
            <textarea
              id="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
              rows={4}
            />
          </div>
          <div className={styles.submitGroup}>
            <button
              type="submit"
              className={
                !name || !email || !message || loading
                  ? styles.disabledBtn
                  : undefined
              }
            >
              Submit
            </button>
            <div>
              {success && (
                <div className={styles.successMessage}>
                  Thank you. The message was sent successfully!
                </div>
              )}
              {error && (
                <div className={styles.errorMessage}>
                  Error submitting. Please try again or send an email instead.
                </div>
              )}
            </div>
          </div>
        </form>
      </div>
      <div className={styles.contactUsEmailContainer}>
        <div className={styles.dontLikeFormsText}>Don't like forms?</div>
        <div>
          <div className={styles.sendEmailInsteadText}>
            Send us an email instead:
          </div>
          <div className={styles.email}>
            <a href={"mailto:hello@mion.group"}>hello@mion.group</a>
          </div>
        </div>
      </div>
    </div>
  );
}
